import * as React from "react";
import { useTranslation } from "react-i18next";
import { StaticImage } from "gatsby-plugin-image";
import { Link, animateScroll as scroll } from 'react-scroll';
import Layout from "../components/layout"
import Seo from "../components/seo"
import * as home from './../css/home.module.css';
import CallToAction from "../components/CallToAction";
  

const IndexPage = () => {
  const scrollToTop = () => {
    scroll.scrollToTop();
  };

  const { t } = useTranslation('index');
  return (
    <Layout>
      <Seo title={t("seoTitle")} />
    
      <section className={home.siteHeading} >
          <div className={home.siteHeadingTexts}>
            <h1>{t("section1Heading0")}</h1>
            <h3>{t("section1Heading1")}</h3>
            <h2>{t("section1Heading2")}</h2>
          </div>
          
       </section>
       
      
      
      <section>
        <CallToAction />
      </section>
    
    </Layout>
  )
}

export default IndexPage
